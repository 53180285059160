/* eslint no-useless-constructor:off, no-unused-vars:off, no-empty-function:off */
export class Margins {
  constructor(
    public top: number,
    public right: number,
    public bottom: number,
    public left: number,
  ) {
  }
}
